import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { PrintInfoProps, ProportionPosNameData } from '@Src/_basic/object/PrintProductionType';
import { PrintProductionApi } from '@Src/_basic/protocol/printProduction/printProductionApi';
import { usePrintProduction } from '@Src/redux/printProduction/printProductionActions';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintProduction from './PrintProduction';

interface Props {
  open: boolean;
  onClose: () => void;
  info: PrintInfoProps;
}

const PrintSelectProportion = ({ open, onClose, info }: Props) => {
  const {
    setProportionPosition,
    proportionPosition,
    setAmsAbnormalLegalData,
    setConcreteExptReportData,
    setBomData,
    setCarbonData,
  } = usePrintProduction();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [date, setDate] = useState<Date | null>(new Date());
  const [proportionOptions, setProportionOptions] = useState<Record<string, string>>({});
  const [isShowAlert, setIsShowAlert] = useState(false);

  const handleSelectDate = useCallback(
    (date: Date | null) => {
      setDate(date);
      PrintProductionApi.getProjectProps(
        {
          ...info,
          shipmentTimestamp: new Date(date as Date).getTime(),
        },
        (proportionDisplayName: Array<ProportionPosNameData>) => {
          setProportionOptions(proportionDisplayName.reduce((options, proportionData) => ({ ...options, [proportionData.displayName]: '' }), {}));
          setProportionPosition(proportionDisplayName.reduce((acc, cur) => ({ ...acc, [cur.displayName]: cur }), {}));
        },
      );
    },
    [info],
  );

  const handleInput = useCallback(
    (proportionName: string, value: string) => {
      if (!proportionPosition) return;
      const data = { ...proportionPosition[proportionName], position: value };
      setProportionPosition({ ...proportionPosition, [proportionName]: data });
    },
    [setProportionPosition, proportionPosition],
  );

  const handleClose = useCallback(() => {
    setProportionPosition({});
    setProportionOptions({});
    onClose();
  }, [setProportionPosition, onClose]);

  const handleSubmitPrint = useCallback(() => {
    if (!proportionPosition) return;

    const error = Object.keys(proportionPosition).find((key) => !proportionPosition[key]);
    if (error) {
      setIsShowAlert(true);
      return;
    }

    const startOfDay = moment(date).startOf('day').toDate();
    const endOfDay = moment(date).endOf('day').toDate();

    Promise.all([
      PrintProductionApi.getAMSAbnormalLegal({
        factoryId: info.factoryId,
        exportTime: new Date(date as Date).getTime(),
      }),
      PrintProductionApi.getConcreteExptReport({
        ...info,
        startTime: new Date(startOfDay).getTime(),
        endTime: new Date(endOfDay).getTime(),
        realPropNames: Object.keys(proportionPosition),
      }),
      PrintProductionApi.getBom({
        ...info,
      }),
      PrintProductionApi.getCarbon({
        startTime: new Date(startOfDay).getTime(),
        endTime: new Date(endOfDay).getTime(),
        orderId: info.orderId,
      }),
    ])
      .then(([abnormalLegal, concreteExptReport, bomData, carbonData]) => {
        if (abnormalLegal) setAmsAbnormalLegalData(abnormalLegal);
        if (concreteExptReport) setConcreteExptReportData(concreteExptReport);
        if (bomData) setBomData(bomData);
        if (carbonData) setCarbonData(carbonData);
      })
      .then(() => {
        handlePrint();
      });
  }, [
    proportionPosition,
    handlePrint,
    setAmsAbnormalLegalData,
    info,
    date,
    setConcreteExptReportData,
    setBomData,
    setCarbonData,
  ]);

  const onCloseAlert = useCallback(() => {
    setIsShowAlert(false);
  }, []);

  return (
    <>
      <YatungDialog
        open={open}
        handleClose={handleClose}
        title={'履歷匯出'}
        body={
          <Stack gap={3.5} pt={3}>
            <Stack justifyContent="center" alignItems="center">
              <YatungDateSelect onChange={handleSelectDate} value={date} label="履歷日期" />
            </Stack>
            <Stack
              sx={{
                border: '1px solid #aaa',
                p: 3,
                maxHeight: '50vh',
                overflowY: 'auto',
                boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Grid container sx={{ borderBottom: '1px solid #ccc', pb: 3, pt: 1 }}>
                <Grid item xs={6}>
                  <Typography>配比編號</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>澆置位置</Typography>
                </Grid>
              </Grid>
              {Object.keys(proportionOptions).map((item) => (
                <Grid
                  key={item}
                  container
                  sx={{
                    borderBottom: '1px solid #ccc',
                    py: 3,
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: 400,
                  }}
                  spacing={1}
                >
                  <Grid item xs={5}>
                    <Typography>{item}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="standard"
                      label={`${item}澆置位置`}
                      onChange={(e) => handleInput(item, e.target.value)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Stack>
            <Stack gap={4} flexDirection="row" justifyContent="flex-end">
              <YatungButton width={100} color="red" text="取消" onClick={handleClose} />
              <YatungButton width={100} color="blue" text="匯出" onClick={handleSubmitPrint} />
            </Stack>
          </Stack>
        }
      ></YatungDialog>
      <YatungConfirmAlert isShowAlert={isShowAlert} onClose={onCloseAlert} alertText="請輸入澆置位置" />
      <Stack sx={{ display: 'none' }}>
        <PrintProduction ref={componentRef} title={info.engName} time={moment(date).format('YYYY/MM/DD')} />
      </Stack>
    </>
  );
};

export default memo(PrintSelectProportion);
