import YatungButton from '@Src/_basic/components/YatungButton';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onGoBack: () => void;
  value: FactoryData;
}
export default function FactoryManagementUpdateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onGoBack, value } = props;

  return (
    <>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        color="red"
        variant="contained"
        size="large"
        onClick={onGoBack}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
          mr: 5,
        }}
      />

      <YatungButton
        disabled={value.removed === true}
        text={i18T('GLOBAL.FINISH')}
        variant="contained"
        type="submit"
        color="blue"
        size="large"
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
        }}
      />
    </>
  );
}
