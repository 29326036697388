import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import ProductionHistoryScreen from './screens/ProductionHistoryScreen';
import ViewProductionHistoryScreen from './screens/ViewProductionHistoryScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/list',
    element: <ProductionHistoryScreen />,
  },
  {
    path: '/view/:factoryId/:orderId/*',
    element: <ViewProductionHistoryScreen />,
  },
];

const ProductionHistory = () => PermissionRoute(routesConfig);

export default memo(ProductionHistory);
