import { Box, Stack } from '@mui/material';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import Content from './Content';
import Header from './Header';

interface Props {
  children: React.ReactNode;
}

const TIMEOUT_MINUTES = 15;

let lastActiveTime: number = new Date().getTime();

export default function Right({ children }: Props) {
  const { logout } = useAuth();

  useEffect(() => {
    console.log('updateLastActiveTime');
    lastActiveTime = new Date().getTime();
  }, [children]);

  useEffect(() => {
    const timer = setInterval(() => {
      const diff = new Date().getTime() - lastActiveTime;
      if (diff > 1000 * 60 * TIMEOUT_MINUTES) {
        logout();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0, height: '100vh' }}>
      <Stack sx={{ height: '100%' }}>
        <Header />
        <Content>{children}</Content>
      </Stack>
    </Box>
  );
}
